<template>
  <div>
    <ValidationObserver ref="formValidator" v-slot="{ invalid, validate }">
      <b-form @submit.prevent="validate().then(save)">
        <b-col md="10">
          <label class="form-control-label">Time for message to be sent</label>
          <v-layout class="pickers mb-2" row wrap>
            <v-time-picker v-model="scheduleTime" width="170"></v-time-picker>
          </v-layout>
        </b-col>
        <b-col md="10 mt-5">
          <label class="form-control-label">Interview reminder message</label>
          <div class="d-flex mb-2">
            <base-button
              v-for="(shortcode, index) in shortcodes"
              :key="`shortcode_${index}`"
              size="sm"
              type="outline-primary"
              @click="addShortcode(shortcode)"
            ><i class="ni ni-fat-add"></i>{{ shortcode }}
            </base-button>
          </div>
          <app-textarea
            ref="messageBlock"
            v-model="message"
            class="template-input"
            rules="required"
          />
          <p :class="{'text-danger' : message.length > messageLimit}"
             class="d-flex justify-content-end">{{ messageInstruction }}</p>
          <div class="d-flex justify-content-end">
            <button :disabled="!on || !isTemplateChanged" class="btn btn-outline-primary"
                    @click.prevent="cancelingEditing">
              Cancel
            </button>
            <button :disabled="invalid || !canSave" class="btn btn-primary">
              Save
            </button>
          </div>
        </b-col>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import AppTextarea from '@/components/App/controls/AppTextarea.vue';
import {UPDATE_INTERVIEW_REMINDER_BY_ID_REQUEST} from '@/store/storeActions';

export default {
  components: {AppTextarea},
  props: {
    interviewReminders: Array,
    selectedTemplate: Object,
    formBlocked: Boolean,
    isTeamTemplate: Boolean,
    on: Boolean,
    canCreate: Boolean,
  },
  data: function() {
    const shortcodes = [
      'FirstName',
      'LastName',
    ];
    return {
      id: '',
      name: '',
      message: '',
      scheduleTime: '',
      shortcodes,
      messageLimit: 400,
      isTemplateChanged: false,
    };
  },
  computed: {
    messageInstruction: function() {
      return this.message.length + '/' + this.messageLimit;
    },
    canSave: function() {
      return (this.message.length <= this.messageLimit) && this.isTemplateChanged;
    },
  },
  watch: {
    selectedTemplate: {
      handler: function(value) {
        this.initializeFields(value);
      },
      immediate: true,
    },
    name: {
      handler: function(value) {
        this.changeFormHandler('name', value);
      },
      immediate: true,
    },
    message: {
      handler: function(value) {
        this.changeFormHandler('message', value);
      },
      immediate: true,
    },
    scheduleTime: {
      handler: function(value) {
        this.changeFormHandler('scheduleTime', value);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapGetters([
      'userAutomationReminder',
      'interviewReminderEnabled',
    ]),
    ...mapActions([
      UPDATE_INTERVIEW_REMINDER_BY_ID_REQUEST,
    ]),
    addShortcode: function(shortcode) {
      let cursorPosition = 0;
      const {messageBlock} = this.$refs;
      if (!!messageBlock) {
        const {textarea} = messageBlock.$refs;
        textarea.focus();
        cursorPosition = textarea ? textarea.selectionStart : 0;
      }
      this.message = `${this.message.slice(0, cursorPosition)}[${shortcode}]${this.message.slice(cursorPosition)}`;
    },
    cancelingEditing: function() {
      this.initializeFields(this.selectedTemplate);
    },
    save: function() {
      const payload = {
        name: this.name,
        message: this.message,
        scheduleTime: this.scheduleTime,
      };
      this.UPDATE_INTERVIEW_REMINDER_BY_ID_REQUEST({payload, id: this.selectedTemplate.id});
      this.isTemplateChanged = false;
      this.$emit('form-blocked-change', false);
    },
    changeFormHandler: function(field, value) {
      const blocked = (this.selectedTemplate && value !== this.selectedTemplate[field]);
      const unsavedFormData = {
        id: this.id,
        name: this.name,
        time: this.scheduleTime,
        message: this.message,
      };
      this.isTemplateChanged = blocked;
      this.$emit('form-block-change', {blocked, unsavedFormData});
    },
    initializeFields: function(template) {
      if (!!template) {
        this.id = template.id;
        this.name = template.name;
        this.scheduleTime = template.scheduleTime;
        this.message = template.message.replace(/\\/g, '');
      } else {
        this.id = null;
        this.name = '';
        this.scheduleTime = '';
        this.message = '';
      }
    },
  },
};
</script>
<style>
.template-input .form-control, .template-input.form-control {
  border-radius: 10px;
}
</style>
