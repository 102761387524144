<template>
  <div>
    <ValidationObserver ref="formValidator" v-slot="{ invalid, validate }">
      <b-form @submit.prevent="validate().then(save)">
        <b-row class="ml-0 day-row">
          <b-col md="5">
            <b-row :class="[{'dummy-input': !isSameDay}]"
                   class="day-row ml-0">
              <base-input
                v-model="delayInDays"
                :showErrorBorder="false"
                :showErrors="false"
                class="day-input"
                name="days"
                rules="required"
                type="text"
                @input="acceptDay"
              >
              </base-input>
              <span class="ml-1 font-weight-bold">Days</span>
            </b-row>
            <b-form-group class="mb-5 font-weight-bold d-block" rules="required">
              <b-form-radio v-model="delayType" :value="templateTypes.BEFORE" name="templateType">
                <span class="ml-2 font-weight-bold">Before start date</span>
              </b-form-radio>
              <b-form-radio v-model="delayType" :value="templateTypes.SAME_DAY" name="templateType">
                <span class="ml-2 font-weight-bold">On start date</span>
              </b-form-radio>
              <b-form-radio v-model="delayType" :value="templateTypes.AFTER" name="templateType">
                <span class="ml-2 font-weight-bold">After start date</span>
              </b-form-radio>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label class="form-control-label">Time for message to be sent</label>
            <v-layout class="pickers mb-2" row wrap>
              <v-time-picker v-model="scheduleTime" width="170"></v-time-picker>
            </v-layout>
            <base-input
              v-model="scheduleTime"
              :showErrors="false"
              class="schedule-time"
              name="scheduleTime"
              rules="required"
              type="hidden">
            </base-input>
          </b-col>
        </b-row>
        <b-row class="ml-0">
          <b-col md="10">
            <base-input
              v-model="name"
              :showErrorBorder="false"
              :showErrors="false"
              class="template-input"
              label="Onboarding template name"
              name="Template name"
              rules="required"
              type="text"
            >
            </base-input>
            <label class="form-control-label">Message</label>
            <div class="d-flex mb-2">
              <base-button
                v-for="(shortcode, index) in shortcodes"
                :key="`shortcode_${index}`"
                size="sm"
                type="outline-primary"
                @click="addShortcode(shortcode)"
              ><i class="ni ni-fat-add"></i>{{ shortcode }}
              </base-button>
            </div>
            <app-textarea
              ref="messageBlock"
              v-model="message"
              class="template-input"
              rules="required"
            />
            <p :class="{'text-danger' : message.length > messageLimit}"
               class="d-flex justify-content-end">{{ messageInstruction }}</p>
            <div class="d-flex justify-content-end">
              <button :disabled="!isOnboarding || !isTemplateChanged" class="btn btn-outline-primary"
                      @click.prevent="cancelingEditing">
                Cancel
              </button>
              <button :disabled="invalid || !canSave" class="btn btn-primary">
                Save
              </button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import {mapActions} from 'vuex';
import AppTextarea from '@/components/App/controls/AppTextarea.vue';
import {templateTypes} from '@/store/userConstants';
import {CREATE_USER_ONBOARGING_REQUEST, UPDATE_TEMPLATE_ONBOARDING_REQUEST} from '@/store/storeActions';

export default {
  components: {AppTextarea},
  props: {
    isOnboarding: Boolean,
    formOnboardBlocked: Boolean,
    selectedOnboardTemplate: Object,
    canCreate: Boolean,
  },
  watch: {
    selectedOnboardTemplate: {
      handler: function(value) {
        this.initializeFields(value);
      },
      immediate: true,
    },
    name: {
      handler: function(value) {
        this.changeFormHandler('name', value);
      },
      immediate: true,
    },
    message: {
      handler: function(value) {
        this.changeFormHandler('message', value);
      },
      immediate: true,
    },
    delayInDays: {
      handler: function(value) {
        this.changeFormHandler('delayInDays', value);
      },
      immediate: true,
    },
    delayType: {
      handler: function(value) {
        this.changeFormHandler('delayType', value);
        this.isSameDay = value !== templateTypes.SAME_DAY;
        if (!this.id) {
          this.delayInDays = value === templateTypes.SAME_DAY ? 0 : null;
        }
      },
      immediate: true,
    },
    scheduleTime: {
      handler: function(value) {
        this.changeFormHandler('scheduleTime', value);
      },
      immediate: true,
    },
  },
  data: function() {
    const shortcodes = [
      'FirstName',
      'LastName',
    ];
    return {
      id: null,
      name: '',
      delayInDays: null,
      message: '',
      scheduleTime: '',
      shortcodes,
      messageLimit: 400,
      templateTypes,
      delayType: templateTypes.BEFORE,
      isTemplateChanged: false,
      isSameDay: false,
    };
  },
  computed: {
    messageInstruction: function() {
      return this.message.length + '/' + this.messageLimit;
    },
    canSave: function() {
      return (this.isTemplateChanged && (this.canCreate && this.selectedOnboardTemplate)) &&
        (this.message.length <= this.messageLimit);
    },
  },
  methods: {
    ...mapActions([
      CREATE_USER_ONBOARGING_REQUEST,
      UPDATE_TEMPLATE_ONBOARDING_REQUEST,
    ]),
    addShortcode: function(shortcode) {
      let cursorPosition = 0;
      const {messageBlock} = this.$refs;
      if (!!messageBlock) {
        const {textarea} = messageBlock.$refs;
        textarea.focus();
        cursorPosition = textarea ? textarea.selectionStart : 0;
      }
      this.message = `${this.message.slice(0, cursorPosition)}[${shortcode}]${this.message.slice(cursorPosition)}`;
    },
    cancelingEditing: function() {
      this.initializeFields(this.selectedOnboardTemplate);
    },
    initializeFields: function(template) {
      if (!!template) {
        this.id = template.id;
        this.name = template.name;
        this.scheduleTime = template.scheduleTime;
        this.message = template.message.replace(/\\/g, '');
        this.delayInDays = template.delayInDays;
        this.delayType = template.delayType;
      } else {
        this.id = null;
        this.name = '';
        this.scheduleTime = '';
        this.message = '';
        this.delayInDays = null;
        this.delayType = templateTypes.BEFORE;
      }
    },
    changeFormHandler: function(field, value) {
      const blocked = this.selectedOnboardTemplate ? (this.selectedOnboardTemplate && value !==
        this.selectedOnboardTemplate[field]) : (value !== '');
      const unsavedFormData = {
        id: this.id,
        name: this.name,
        message: this.message,
        days: this.delayInDays,
        scheduleTime: this.scheduleTime,
        templateType: this.delayType,
      };
      if (this.id && !this.isTemplateChanged) {
        this.delayInDays = this.delayType !== templateTypes.SAME_DAY && this.delayInDays === 0 ?
          null :
          this.delayInDays;
      }
      this.isTemplateChanged = blocked;
      (!!this.$refs.formValidator) && this.$refs.formValidator.validate().then((result) => {
        this.$emit('form-onboard-block-change', {blocked, unsavedFormData});
      });
    },
    acceptDay() {
      if (this.delayType !== this.templateTypes.SAME_DAY) {
        const x = this.delayInDays.replace(/\D/g, '').match(/^([1-9]|[1-9][0-9]|100)$/);
        this.delayInDays = x ? x[0] : '';
      }
    },
    save: function() {
      const payload = {
        name: this.name,
        automationType: 'onboardingMessage',
        message: this.message,
        scheduleTime: this.scheduleTime,
        delayType: this.delayType,
        delayInDays: this.delayType === this.templateTypes.SAME_DAY ? 0 : this.delayInDays,
      };
      !this.selectedOnboardTemplate || !this.selectedOnboardTemplate.id ?
        this.CREATE_USER_ONBOARGING_REQUEST({payload}) :
        this.$emit('update-onboard-template', {payload, id: this.id});
      this.isTemplateChanged = false;
      this.$emit('enable-new-template');
    },
  },
};
</script>
<style>
.day-row {
  align-items: baseline;
}

.template-input .form-control, .template-input.form-control {
  border-radius: 10px;
  margin-bottom: 0;
}

.template-input .form-group,
.schedule-time .form-group {
  margin-bottom: 0;
}

.day-input .form-control, .template-input.form-control {
  width: 5rem;
}

.dummy-input * {
  opacity: 0;
  cursor: default !important;
}
</style>
