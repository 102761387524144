<template>
  <div>
    <div class="d-flex align-items-center justify-content-between p-3 pb-2 stage-title-main">
      <div class="stage-title">{{ name }}</div>
    </div>
    <ValidationObserver ref="formValidators" v-slot="{ invalid, validate }">
      <b-form class="mt-2 p-3" @submit.prevent="validate().then(save)">
        <label class="form-control-label">Message</label>
        <app-textarea ref="messageBlock"
                      v-model="message" class="template-input" placeholder="Type here..." rules="required"/>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex mt-2">
            <b-button v-for="(shortcode, index) in shortcodes" :key="`shortcode_${index}`"
                      class="d-flex justify-content-between align-items-center  automation-btn"
                      pill size="sm"
                      variant="outline-primary"
                      @click="addShortcode(shortcode)">
              <span>{{ shortcode }}</span>
              <b-icon class="ml-1 automation-plus-icon" icon="plus"></b-icon>
            </b-button>
          </div>
          <div>
            <p :class="{ 'text-danger': message.length > messageLimit }" class="text-right ml-2 mt-2">
              {{ messageInstruction }}
            </p>
          </div>
        </div>
        <div class="d-flex align-items-center mt-1">
          <span>Message will be sent</span>
          <base-input v-model="hours" class="hour-input mt-3 ml-1 mr-1" name="hours" type="text"
                      @input="acceptHours"></base-input>
          <span>{{ hoursText }} after the stage change</span>
        </div>
        <div class="d-flex justify-content-end">
          <button :disabled="!isSaveEnabled" class="btn btn-outline-primary" @click.prevent="cancelingEditing">
            Cancel
          </button>
          <button :disabled="invalid || !isSaveEnabled" class="btn btn-primary">
            Save
          </button>
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import AppTextarea from '@/components/App/controls/AppTextarea.vue';

export default {
  components: {
    AppTextarea,
  },
  props: {
    selectedItem: Object,
    isSaveEnabled: Boolean,
  },
  data() {
    const shortcodes = [
      'First Name',
      'Last Name',
      'Recruiter Name',
      'Job Title',
      'Job Location',
      'Job Department',
    ];
    return {
      shortcodes,
      id: '',
      name: '',
      message: '',
      messageLimit: 400,
      hours: this.selectedItem.hours || null,
      hoursText: 'hour',
      isEnabled: this.selectedItem.isEnabled,
    };
  },
  watch: {
    selectedItem: {
      handler: function(value) {
        this.initializeFields(value);
      },
      immediate: true,
    },
    hours: {
      handler: function(value) {
        this.hours = value;
        this.hoursText = this.hours > 1 ? 'hours' : 'hour';
        this.changeFormHandler('hours', value);
      },
      immediate: true,
    },
    message: {
      handler: function(value) {
        this.changeFormHandler('message', value);
      },
      immediate: true,
    },
  },
  computed: {
    messageInstruction: function() {
      return this.message.length + '/' + this.messageLimit;
    },
  },
  methods: {
    acceptHours: function() {
      const hours = this.hours.replace(/\D/g, ''); // Extract only digits from the input
      this.hours = /^(0?[1-9]|1\d|2[0-4])$/.test(hours) ? hours : '';
    },
    addShortcode: function(shortcode) {
      let cursorPosition = 0;
      const {messageBlock} = this.$refs;
      if (!!messageBlock) {
        const {textarea} = messageBlock.$refs;
        textarea.focus();
        cursorPosition = textarea ? textarea.selectionStart : 0;
      }
      this.message = `${this.message.slice(0, cursorPosition)}[${shortcode}]${this.message.slice(cursorPosition)}`;
    },
    initializeFields: function(template) {
      if (!!template) {
        this.id = template.id;
        this.name = template.name;
        this.message = template.message.replace(/\\/g, '');
        // this.message = '';
        this.hours = template.hours;
        this.isEnabled = template.isEnabled;
      }
    },
    cancelingEditing: function() {
      this.initializeFields(this.selectedItem);
    },
    save: function() {
      this.$emit('save-form-click');
      // this.cancelingEditing();
    },
    changeFormHandler: function(field, value) {
      const blocked = this.selectedItem ? (this.selectedItem && value !==
        this.selectedItem[field]) : (value !== '');
      if (this.message !== '' && blocked) {
        const unSavedFormData = {
          id: this.id,
          message: this.message,
          hours: this.hours,
        };
        this.$emit('form-stage-block-change', {blocked, unSavedFormData});
      }
    },
  },
};
</script>
<style>
.hour-input .form-control {
  width: 2.75rem;
  border-radius: 10px;
  text-align: center;
  height: 2.5rem;
  font-size: 0.80rem;
}

.hour-input .form-group {
  margin-bottom: 0px;
}

</style>
