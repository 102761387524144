<template>
  <div bodyClasses="p-0">
    <template>
      <div class="d-flex justify-content-between align-items-center mt-4 pl-3">
        <h3 class="mb-0">Jobs list</h3>
        <div class="d-flex align-items-center">
          <el-input v-model="searchQuery" class="search-form mr-3" clearable
                    placeholder="Search..." @input="handleSearch">
            <i slot="prefix" class="el-input__icon el-icon-search text-dark"></i>
          </el-input>
        </div>
      </div>
    </template>
    <div class="p-3">
      <el-table
        ref="jobTableRef"
        :data="paginatedData"
        class="table-responsive custom-table table-flush"
        header-row-class-name="thead-light"
        show-empty
        @sort-change="handleSortChange"
      >
        <template #empty>
          <span class="table-empty">
            There are currently no open jobs that contain this pipeline stage.
          </span>
        </template>
        <el-table-column :width="columnWidths.jobTitle" label="Job Title" prop="jobTitle" sortable>
          <template v-slot="{ row }">
            <div class="d-flex align-items-center" @click="handleJobAction(row, $event)">
              <base-switch v-model="row.isSelected" class="custom-switch mr-2"
                           offText="Off" onText="On"></base-switch>
              <span>{{ row.jobTitle }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Created date" prop="createdDate" sortable>
          <template v-slot="{ row }">
            {{ row.createdDate | formatDate }}
          </template>
        </el-table-column>
        <el-table-column label="Location" prop="location" sortable>
        </el-table-column>
        <el-table-column label="Department" prop="department" sortable>
        </el-table-column>
        <el-table-column :width="columnWidths.recruiter" label="Recruiter" prop="recruiter" sortable>
        </el-table-column>
      </el-table>

      <!-- Pagination component -->
      <el-pagination
        :current-page="currentPage"
        :page-size="pageSize"
        :total="sortedAndFilteredData.length"
        background
        class="m-4 float-right custom-pagination"
        layout="prev, pager, next"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import {Input, Pagination, Table, TableColumn} from 'element-ui';

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    [Input.name]: Input,
  },
  data() {
    return {
      search: '',
      searchQuery: '',
      loading: false,
      currentPage: 1,
      pageSize: 10,
      sortKey: '',
      sortOrder: '',
      columnWidths: {
        jobTitle: '300px',
        recruiter: '150px',
      },
      isAddClassToTable: false,
    };
  },
  props: {
    isEnabled: Boolean,
    jobs: Array,
  },
  watch: {
    isEnabled: {
      handler: function(value) {
        this.isAddClassToTable = value;
        this.addTbodyClass();
      },
    },
  },
  computed: {
    sortedAndFilteredData() {
      let data = this.jobs;
      if (this.searchQuery) {
        const searchLower = this.searchQuery.trim().toLowerCase();
        data = data.filter((item) => {
          return Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchLower),
          );
        });
      }
      // Sorting
      if (this.sortKey) {
        data = data.slice().sort((a, b) => {
          let result = 0;
          if (a[this.sortKey] < b[this.sortKey]) {
            result = -1;
          } else if (a[this.sortKey] > b[this.sortKey]) {
            result = 1;
          }
          return this.sortOrder === 'ascending' ? result : -result;
        });
      }
      return data;
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.sortedAndFilteredData.slice(start, end);
    },
  },
  methods: {
    handleSearch() {
      this.currentPage = 1;
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    handleSortChange({prop, order}) {
      this.sortKey = prop;
      this.sortOrder = order;
      this.currentPage = 1; // Reset to first page on search
    },
    addTbodyClass() {
      if (this.$refs.jobTableRef) {
        const tableElement = this.$refs.jobTableRef.$el;
        const tbody = tableElement.querySelector('tbody');
        if (!this.isAddClassToTable) {
          tbody.classList.add('grayed-out');
        } else {
          tbody.classList.remove('grayed-out');
        }
      }
    },
    handleJobAction(row, event) {
      if (event.target.tagName === 'INPUT') {
        const item = this.jobs.find((item) => item.id === row.id);
        if (item) {
          item.isSelected = !item.isSelected;
        }
      }
    },
  },
  mounted() {
    this.addTbodyClass();
  },
};
</script>
<style>
.custom-switch {
  width: 1rem !important;
}

.custom-table {
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  overflow: hidden;
  border-collapse: separate;
}

.table-empty {
  font-size: 13px;
  font-weight: 400;
  line-height: 17.7px;
  text-align: left;
}

.grayed-out {
  background-color: #e9ecef;
}
</style>
