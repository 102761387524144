<template>
  <div class="card stage-list">
    <b-row class="g-0" no-gutters>
      <b-col class="stage-list-data" md="3">
        <div ref="stageTitle" class="d-flex align-items-center justify-content-between p-3 pl-3 pb-2 stage-title-main">
          <div class="stage-title">{{ 'Stage' }}</div>
        </div>
        <div v-if="jobStages.length > 0">
          <div v-for="(item, index) in jobStages" :key="index">
            <div :class="{ 'active': activeIndex === index,
              'first-item': isFirstItem(index) }"
                 class="app-border p-3 position-relative active-element stage-card d-flex justify-content-between "
                 @click="toggleActive(index, $event)">
              <div class="content-container">
                <div class="mb-2 name"><b>{{ item.name }}</b></div>
                <div :class="{ 'text-dark': activeIndex === index}"
                     class="font-400 gray-600-color pre-md-text">
                  {{ item.jobTitle }}<span class="bullet-list"></span>{{ item.jobs.length }} Jobs
                </div>
              </div>
              <base-switch v-model="item.isEnabled" class="align-self-center custom-switch"
                           offText="Off" onText="On"></base-switch>
            </div>
          </div>
        </div>
        <div v-if="jobStages.length === 0">
          <NoIntegrate/>
        </div>
      </b-col>
      <b-col v-if="activeIndex >= 0 && activeMenu === 'stage_changes'" md="9">
        <StageDetail :isSaveEnabled="isSaveEnabled" :selectedItem="selectedDetail" class="stage-detail pb-3"
                     @save-form-click="stageDetailSave" @form-stage-block-change="checkFormChangeHandler"/>
        <JobTable :isEnabled="selectedDetail.isEnabled" :jobs="jobStages[activeIndex].jobs"/>
      </b-col>
      <b-col v-else-if="activeIndex >= 0 && activeMenu === 'interview'" md="9">
        <InterviewDetail ref="interviewDetail" :isSaveEnabled="isSaveEnabled" :selectedItem="selectedDetail"
                         class="stage-detail pb-3"
                         @save-form-click="stageDetailSave"
                         @form-interview-block-change="checkInterviewFormChangeHandler"/>
        <JobTable :isEnabled="selectedDetail.isEnabled" :jobs="jobStages[activeIndex].jobs"/>
      </b-col>
      <b-col v-else class="text-center" md="9">
        <div class="d-flex align-items-center justify-content-between p-2 pl-3 pb-2 stage-title-main no-stage">
          <div class="stage-title"></div>
        </div>
        <div class="mt-4">
          {{ 'Select the Stage you want to work on' }}
        </div>
      </b-col>
    </b-row>
    <confirmation-modal ref="saveStageChangesModal" cancelBtnText="Cancel" confirmBtnText="Save"
                        header="Save changes"
                        question="You have made changes to this stage. Do you want to save before you leave?"
                        @on-accept="saveStageChanges" @on-reject="changeStageTemplate"/>
    <confirmation-modal ref="saveInterviewChangesModal" cancelBtnText="Cancel" confirmBtnText="Save"
                        header="Save changes"
                        question="You have made changes to this stage. Do you want to save before you leave?"
                        @on-accept="saveInterviewChanges" @on-reject="changeInterviewTemplate"/>

    <!-- Stage Detail modals -->
    <confirmation-modal ref="stageApplyModal" :isClose="true"
                        cancelBtnText="No"
                        confirmBtnText="Apply to all jobs" confirmBtnType="primary" header="Apply to all?"
                        question="Apply this new automation to all the existing jobs that contain this stage?"
                        @on-accept="applyAllJob" @on-reject="cancelApplyAllJob"/>
    <confirmation-modal ref="stageTurnOnModal" :isClose="true"
                        cancelBtnText="Keep off"
                        confirmBtnText="Turn on" confirmBtnType="primary" header="Turn on this automation?"
                        question="Now that you’ve configured this automation, would you like to turn it on?"
                        @on-accept="turnOnApplyJob" @on-reject="keepOffApplyJob"/>
  </div>
</template>
<script>
import StageDetail from './StageDetail.vue';
import InterviewDetail from '../interviews/InterviewDetail.vue';
import JobTable from './JobTable.vue';
import NoIntegrate from './NoIntegrate.vue';
import ConfirmationModal from '@/components/App/modals/ConfirmationModal.vue';
import {mapActions, mapGetters} from 'vuex';
import {FETCH_JOB_STAGES_REQUEST} from '@/store/storeActions';

export default {
  components: {
    StageDetail,
    JobTable,
    NoIntegrate,
    InterviewDetail,
    ConfirmationModal,
  },
  props: {
    activeMenu: String,
  },
  data: function() {
    return {
      activeIndex: -1, // Initially first card is active
      selectedDetail: null, // To store the selected item's detail
      switchState: false,
      formBlocked: false,
      unSavedFormData: null,
      formInterviewBlocked: false,
      unsavedInterviewFormData: null,
      tempIndex: null,
      tempInterviewIndex: null,
      isSaveEnabled: false,
    };
  },
  computed: {
    ...mapGetters([
      'accountType',
      'jobStages',
      'jobStagesLoaded',
      'featuresEnabled',
    ]),
    isFirstItem: function() {
      return (index) => index === 0;
    },
  },
  methods: {
    ...mapActions([
      FETCH_JOB_STAGES_REQUEST,
    ]),
    toggleActive: function(index, event) {
      if (this.formBlocked && index !== this.activeIndex) {
        this.$refs.saveStageChangesModal.openModal(index, this.unSavedFormData);
        return true;
      }
      if (this.formInterviewBlocked && index !== this.activeIndex) {
        this.$refs.saveInterviewChangesModal.openModal(index, this.unsavedInterviewFormData);
        return true;
      }
      if (event.target.tagName === 'INPUT') {
        this.$set(this.jobStages, index, {...this.jobStages[index], isEnabled: !this.jobStages[index].isEnabled});
        if (this.unSavedFormData && this.unSavedFormData.id === this.jobStages[index].id) {
          this.$set(this.jobStages, index, {...this.jobStages[index], message: this.unSavedFormData.message});
        }
        this.selectedDetail = this.jobStages[index];
        return true;
      }
      if (this.$refs.interviewDetail && index !== this.activeIndex) {
        this.$refs.interviewDetail.interviewOptions.map((item) => {
          item.isSelected = false;
          item.message = '';
        });
        this.$refs.interviewDetail.selectedOptionIndex = 0;
        this.$refs.interviewDetail.selectedOption = this.$refs.interviewDetail.interviewOptions[0];
      }
      this.activeIndex = index; // Activate clicked card
      this.selectedDetail = this.jobStages[index];
    },
    checkFormChangeHandler: function({blocked, unSavedFormData}) {
      this.formBlocked = blocked;
      this.unSavedFormData = unSavedFormData;
      this.isSaveEnabled = true;
    },
    saveStageChanges: function(index) {
      this.tempIndex = index;
      this.stageDetailSave();
      // this.jobStages.map((item)=>{
      //   item.isEnabled = false;
      // });
      // this.formBlocked = false;
      // this.activeIndex = index;
      // this.selectedDetail = this.jobStages[index];
    },
    changeStageTemplate: function(index) {
      this.jobStages.map((item) => {
        item.isEnabled = false;
      });
      this.activeIndex = index;
      this.selectedDetail = this.jobStages[index];
      this.formBlocked = false;
    },
    checkInterviewFormChangeHandler: function({blocked, unSavedFormData}) {
      this.formInterviewBlocked = blocked;
      this.unsavedInterviewFormData = unSavedFormData;
      this.isSaveEnabled = true;
    },
    changeInterviewTemplate: function(index) {
      this.jobStages.map((item) => {
        item.isEnabled = false;
      });
      this.activeIndex = index;
      this.selectedDetail = this.jobStages[index];
      this.formInterviewBlocked = false;
      this.$refs.interviewDetail.selectedOption = this.$refs.interviewDetail.interviewOptions[0];
      this.$refs.interviewDetail.selectedOptionIndex = 0;
      this.$refs.interviewDetail.interviewOptions.map((item) => {
        item.isSelected = false;
        item.message = '';
      });
      this.isSaveEnabled = false;
    },
    saveInterviewChanges: function(index) {
      this.tempInterviewIndex = index;
      this.stageDetailSave();
      // this.jobStages.map((item)=>{
      //   item.isEnabled = false;
      // });
      // this.formInterviewBlocked = false;
      // this.activeIndex = index;
      // this.selectedDetail = this.jobStages[index];
      // this.$refs.interviewDetail.selectedOption = this.$refs.interviewDetail.interviewOptions[0];
      // this.$refs.interviewDetail.selectedOptionIndex = 0;
      // this.$refs.interviewDetail.interviewOptions.map((item)=>{
      //   item.isSelected = false;
      //   item.message = '';
      // });
    },
    turnOnApplyJob: function() {
      this.selectedDetail.isEnabled = true;
      this.$refs.stageApplyModal.openModal();
    },
    stageDetailSave: function() {
      if (this.selectedDetail.isEnabled) {
        this.$refs.stageApplyModal.openModal();
      } else {
        this.$refs.stageTurnOnModal.openModal();
      }
    },
    keepOffApplyJob: function() {
      if (this.$refs.interviewDetail) {
        if (this.tempInterviewIndex) {
          this.selectedDetail = this.jobStages[this.tempInterviewIndex];
          this.activeIndex = this.tempInterviewIndex;
        }
        this.$refs.interviewDetail.selectedOption = this.$refs.interviewDetail.interviewOptions[0];
        this.$refs.interviewDetail.selectedOptionIndex = 0;
        this.$refs.interviewDetail.interviewOptions.map((item) => {
          item.isSelected = false;
          item.message = '';
        });
      } else {
        if (this.tempIndex) {
          this.selectedDetail = this.jobStages[this.tempIndex];
          this.activeIndex = this.tempIndex;
        }
      }
      this.tempIndex = null;
      this.tempInterviewIndex = null;
      this.formBlocked = false;
      this.unSavedFormData = null;
      this.formInterviewBlocked = false;
      this.unsavedInterviewFormData = null;
      this.isSaveEnabled = false;
    },
    applyAllJob: function() {
      if (this.tempIndex) {
        this.jobStages.map((item) => {
          // item.isEnabled = false;
          // item.message = '';
        });
        this.activeIndex = this.tempIndex;
        this.selectedDetail = this.jobStages[this.tempIndex];
        this.tempIndex = null;
      }
      if (this.tempInterviewIndex) {
        this.jobStages.map((item) => {
          // item.isEnabled = false;
          // item.message = '';
        });
        this.activeIndex = this.tempInterviewIndex;
        this.selectedDetail = this.jobStages[this.tempInterviewIndex];
        this.tempInterviewIndex = null;
        if (this.$refs.interviewDetail) {
          this.$refs.interviewDetail.selectedOption = this.$refs.interviewDetail.interviewOptions[0];
          this.$refs.interviewDetail.selectedOptionIndex = 0;
          this.$refs.interviewDetail.interviewOptions.map((item) => {
            item.isSelected = false;
            item.message = '';
          });
        }
      }
      this.formBlocked = false;
      this.unSavedFormData = null;
      this.formInterviewBlocked = false;
      this.unsavedInterviewFormData = null;
      this.isSaveEnabled = false;
      // this.selectedDetail.message = '';
      // this.selectedDetail.isEnabled = false;
      // if (this.$refs.interviewDetail) {
      //   this.$refs.interviewDetail.selectedOption = this.$refs.interviewDetail.interviewOptions[0];
      //   this.$refs.interviewDetail.selectedOptionIndex = 0;
      //   this.$refs.interviewDetail.interviewOptions.map((item)=>{
      //     item.isSelected = false;
      //     item.message = '';
      //   });
      // }
    },
    cancelApplyAllJob: function() {
      this.formBlocked = false;
      this.unSavedFormData = null;
      this.formInterviewBlocked = false;
      this.unsavedInterviewFormData = null;
      this.isSaveEnabled = false;
      // this.selectedDetail.message = '';
      // this.selectedDetail.isEnabled = false;
      // if (this.$refs.interviewDetail) {
      //   this.$refs.interviewDetail.selectedOption = this.$refs.interviewDetail.interviewOptions[0];
      //   this.$refs.interviewDetail.selectedOptionIndex = 0;
      //   this.$refs.interviewDetail.interviewOptions.map((item)=>{
      //     item.isSelected = false;
      //     item.message = '';
      //   });
      // }
    },
  },
  mounted: function() {
    const noStageElement = document.getElementsByClassName('no-stage')[0];
    if (noStageElement) {
      noStageElement.style.height = this.$refs.stageTitle.clientHeight + 1 + 'px';
    }
    if (!this.jobStagesLoaded) {
      this.FETCH_JOB_STAGES_REQUEST();
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/argon.scss';

.stage-card {
  border-radius: 0px;
  border-right: 0;
}

.stage-list {
  max-height: 80vh;
  overflow-y: auto;
  height: 90vh;
}

.no-integrate {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 1.2px;
}

.active {
  border-left: 4px solid $primary;
  background-color: #EDF8FF;

  .name {
    color: $primary;
  }
}

.plus-icon {
  color: $primary;
}

.stage-list-data {
  height: fit-content;
  border: 0px;
  border-right: 1px solid #EFEFEF;
  border-top: 0;
  margin-bottom: -15px;
}

.stage-detail {
  border-bottom: 1px solid #EFEFEF;
}

.custom-switch {
  width: 1rem !important;
}

.first-item {
  border-top: 0;
}

.no-stage {
  height: 37px;
}

.bullet-list::before {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 5px;
  height: 5px;
  background-color: #435451;
  margin: 0 6px;
  border-radius: 50%;
  opacity: 0.3;
  content: "•";
  color: #435451;
}

</style>
